import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-97f37064"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "filter-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_custom_select = _resolveComponent("custom-select")!
  const _component_custom_date_picker = _resolveComponent("custom-date-picker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.config, (item) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: item.label
      }, [
        (item.type === 'select')
          ? (_openBlock(), _createBlock(_component_custom_select, {
              key: 0,
              modelValue: item.prop,
              "onUpdate:modelValue": ($event: any) => ((item.prop) = $event),
              label: item.showValue ? `${item.label}${_ctx.transformValue(item)}` : `${item.label}`
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.options, (option) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    key: option.value,
                    label: option.label,
                    value: option.value
                  }, null, 8, ["label", "value"]))
                }), 128))
              ]),
              _: 2
            }, 1032, ["modelValue", "onUpdate:modelValue", "label"]))
          : _createCommentVNode("", true),
        (item.type === 'datepicker')
          ? (_openBlock(), _createBlock(_component_custom_date_picker, {
              key: 1,
              label: (item.prop[0] && item.prop[0]) ? `提交时间: ${_ctx.transformDateValue(item.prop)}` : `提交时间`,
              modelValue: item.prop,
              "onUpdate:modelValue": ($event: any) => ((item.prop) = $event),
              showClearBtn: item.showClearBtn
            }, null, 8, ["label", "modelValue", "onUpdate:modelValue", "showClearBtn"]))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ]))
}