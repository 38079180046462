import {
  ISampleClothesFindSampleClothesDetailByIdReq,
  ISampleClothesSubmitSampleClothesReq,
} from '@/modules/task-manage/sample-dress/api/type.d';
import { getSampleClothesDetail, saveSampleClothesDetail } from '@/modules/task-manage/sample-dress/api';

export default () => {
  const getDetail = async (params: ISampleClothesFindSampleClothesDetailByIdReq) => {
    try {
      const res = await getSampleClothesDetail(params);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };
  const saveDetail = async (params: ISampleClothesSubmitSampleClothesReq) => {
    try {
      const res = await saveSampleClothesDetail(params);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };
  return { getDetail, saveDetail };
};
