import { ISampleClothesUpdateStatusBySampleClothesNumReq } from '@/modules/task-manage/sample-dress/api/type.d';
import { updateSampleClothes } from '@/modules/task-manage/sample-dress/api';

export default () => {
  const updateSampleCloth = async (params:ISampleClothesUpdateStatusBySampleClothesNumReq) => {
    try {
      const res = await updateSampleClothes(params);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };
  return {
    updateSampleCloth,
  };
};
