
import { defineComponent, PropType, StyleHTMLAttributes } from 'vue';

export default defineComponent({
  props: {
    theme: {
      type: String,
      default: '#282A33',
    },
    color: {
      type: String,
      default: '#fff',
    },
    style: {
      type: Object as PropType<StyleHTMLAttributes>,
      default: () => ({}),
    },
  },
  setup(props) {
    return {
      props,
    };
  },
});
