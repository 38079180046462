// 操作类型
export enum OPERATOR_TYPE_ENUM {
  /* 新增 */
  CREATE = '0',
  /* 修改 */
  UPDATE = '1',
  /* 删除 */
  DELETE = '2',
}

// 操作类型
export enum INSPIRATION_IMG_TYPE_ENUM {
  /* 本地上传图片 */
  LOCAL = '0',
  /* ES查询条件SPUID */
  ES = '1',
  /* 电商库SPUID */
  STYLE = '2',
}

export enum CHBOXCODE_ENUM{
  /* 设计任务名称 */
  TASK_NAME = '0',
  /* 客户名称 */
  CUSTOMER_NAME = '1',
  /* 设计任务ID */
  TASK_ID = '2',
  /* 图片任务ID */
  IMAGE_ID = '3',
}

// 设计任务状态
export enum TASK_STATE_ENUM{
  /* 待提交 */
  UN_CONFIRM = '0',
  /* 已提交 */
  CONFIRM = '1',
}
export const TASK_STATE_LIST = [
  { value: TASK_STATE_ENUM.CONFIRM, label: '已提交' },
  { value: TASK_STATE_ENUM.UN_CONFIRM, label: '待提交' },
];

// 年份下拉选项
export const YEARS_LIST = [
  { value: '2021', label: '2021' },
  { value: '2022', label: '2022' },
  { value: '2023', label: '2023' },
  { value: '2024', label: '2024' },
  { value: '2025', label: '2025' },
  { value: '2026', label: '2026' },
  { value: '2027', label: '2027' },
  { value: '2028', label: '2028' },
  { value: '2029', label: '2029' },
  { value: '2030', label: '2030' },
];

// 季节下拉选项
export const SEASON_LIST = [
  { value: '春', label: '春' },
  { value: '夏', label: '夏' },
  { value: '秋', label: '秋' },
  { value: '冬', label: '冬' },
];

export enum SELECT_IMAGE_SOURCE_ENUM {
  /* 资源库 */
  LINK = '0',
  /* 插件采集 */
  PLUGIN_COLLECT = '1',
  /* 批量采集 */
  BATCH_COLLECT = '3',
}

// 设计任务状态
export enum TASK_STATUS_ENUM{
  /* 待筛选款式 -> 素材 */
  WAIT_SCREEN = '0',
  /* 待客选款式 -> 待筛选 */
  WAIT_CUSTOMER_SELECT = '1',
  /* 待改款式 -> 待改 */
  WAIT_UPDATE = '2',
  /* 淘汰款式 -> 淘汰 */
  FAIL = '3',
  /* 待提版房 -> 通过 */
  WAIT_SUBMIT_YBF = '4',
  /* 待创建版单 */
  WAIT_CREATE_ORDER = '5',
  /* 已创建版单 */
  CREATED_ORDER = '6',
}

export const TASK_STATUS_LIST = [
  { label: '素材', value: TASK_STATUS_ENUM.WAIT_SCREEN },
  { label: '待筛选', value: TASK_STATUS_ENUM.WAIT_CUSTOMER_SELECT },
  { label: '待改', value: TASK_STATUS_ENUM.WAIT_UPDATE },
  { label: '淘汰', value: TASK_STATUS_ENUM.FAIL },
  { label: '通过', value: TASK_STATUS_ENUM.WAIT_SUBMIT_YBF },
  // { label: '待创建版单', value: TASK_STATUS_ENUM.WAIT_CREATE_ORDER },
  // { label: '已创建版单', value: TASK_STATUS_ENUM.CREATED_ORDER },
];

// 可视范围
export enum VISIBLE_ENUM {
  /* 仅自己可见 */
  SELF = '1',
  /* 部分人可见 */
  PART = '2',
  /* 全部人可见 */
  ALL = '3',
}

export const VISIBLE_LIST = [
  { value: VISIBLE_ENUM.SELF, label: '仅自己可见' },
  { value: VISIBLE_ENUM.PART, label: '部分人可见' },
  { value: VISIBLE_ENUM.ALL, label: '全部人可见' },
];

// 审批状态
export enum AUDIT_STATUS_ENUM {
  /* 淘汰 */
  FAIL = '-1',
  /* 待改 */
  WAIT_UPDATE = '1',
  /* 通过 */
  PASS = '2',
}

// 排序方式
export enum SORT_ENUM {
  /* 待筛选款式数量 */
  NUMBER_OF_WAIT_SCREEN = 'numberOfWaiting',
  /* 待客选款式数量 */
  NUMBER_OF_WAIT_CUSTOMER_SELECT = 'numberOfCustomer',
  /* 待改款式数量 */
  NUMBER_OF_WAIT_UPDATE = 'numberOfCorrection',
  /* 淘汰款式数量 */
  NUMBER_OF_FAIL = 'numberOfFailure',
  /* 待提版房数量 */
  NUMBER_OF_WAIT_SUBMIT_YBF = 'numberOfSubmit',
  /* 待创建版单数量 */
  NUMBER_OF_WAIT_CREATE_ORDER = 'numberOfCreating',
  /* 已创建版单数量 */
  NUMBER_OF_CREATE_ORDER = 'numberOfCreated',
  /* 创建时间 */
  CREATE_TIME = 'createdTime',
}

export const SORT_LIST = [
  { value: SORT_ENUM.NUMBER_OF_WAIT_SCREEN, label: '待筛选款式' },
  { value: SORT_ENUM.NUMBER_OF_WAIT_CUSTOMER_SELECT, label: '待客选款式' },
  { value: SORT_ENUM.NUMBER_OF_WAIT_UPDATE, label: '待改款式' },
  { value: SORT_ENUM.NUMBER_OF_FAIL, label: '淘汰款式' },
  { value: SORT_ENUM.NUMBER_OF_WAIT_SUBMIT_YBF, label: '待提版房' },
  { value: SORT_ENUM.NUMBER_OF_WAIT_CREATE_ORDER, label: '待创建版单' },
  { value: SORT_ENUM.NUMBER_OF_CREATE_ORDER, label: '已创建版单' },
  { value: SORT_ENUM.CREATE_TIME, label: '创建时间' },
];

// 升降序方式
export enum ORDER_ENUM {
  /** 升序 */
  ASC = 'ascending',
  /** 降序 */
  DESC = 'descending',
}

// 款式状态
export enum STYLE_STATUS_ENUM {
  /** 素材 */
  MATERIAL = '0',
  /** 款式 */
  STYLE = '1',
}

export const STYLE_STATUS_LIST = [
  { label: '素材', value: STYLE_STATUS_ENUM.MATERIAL },
  { label: '款式', value: STYLE_STATUS_ENUM.STYLE },
];

// 选图结果
export enum RESULT_STATUS_ENUM{
  /*  待筛选 */
  WAIT_FILTER = '0',
  /*  待改 */
  WAIT_UPDATE = '1',
  /*  淘汰 */
  FAIL = '-1',
  /*  通过 */
  PASS = '2',
}
// 选图结果
export const RESULT_STATUS_LIST = [
  { value: RESULT_STATUS_ENUM.WAIT_FILTER, label: '待筛选' },
  { value: RESULT_STATUS_ENUM.WAIT_UPDATE, label: '待改' },
  { value: RESULT_STATUS_ENUM.FAIL, label: '淘汰' },
  { value: RESULT_STATUS_ENUM.PASS, label: '通过' },
];
