import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createBlock(_component_el_input, _mergeProps({
    modelValue: _ctx.keyword,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.keyword) = $event))
  }, _ctx.$attrs, {
    class: "search-box",
    style: {['--input-width']: _ctx.inputWidth},
    placeholder: _ctx.placeholder,
    onKeydown: _withKeys(_ctx.handleConfirmKeyword, ["enter"]),
    onUpdate: _ctx.updateKeyWord
  }), {
    suffix: _withCtx(() => [
      _createElementVNode("i", {
        class: "iconfont icon_icon_search container_search_icon",
        onClick: _cache[0] || (_cache[0] = () => {_ctx.handleConfirmKeyword(_ctx.keyword)})
      })
    ]),
    _: 1
  }, 16, ["modelValue", "style", "placeholder", "onKeydown", "onUpdate"]))
}