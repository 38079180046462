import http from '@/core/http';
import { DOMAIN_SYSTEM_ENUM } from 'cx-utils';
import { exportByBlob } from '@/core/utils/file-download';
import {
  ISampleClothesFindSampleClothesCollectionReq,
  ISampleClothesFindSampleClothesCollectionRes,
  ISampleClothesFindSampleClothesDetailByIdReq,
  ISampleClothesFindSampleClothesDetailByIdRes,
  ISampleClothesSubmitSampleClothesReq,
  ISampleClothesSubmitSampleClothesRes,
  ISupplierFindSupplierByOwnerRes,
  ISampleClothesExportSampleClothesReq,
  ISampleClothesUpdateStatusBySampleClothesNumReq,
  ISupplierFindAllSupplierRes,
} from './type.d';

const server = DOMAIN_SYSTEM_ENUM.tiangong;

/**
 * 获取样衣列表
 */
export function getSampleClothesList(data: ISampleClothesFindSampleClothesCollectionReq) {
  return http.post<ISampleClothesFindSampleClothesCollectionRes>({
    url: '/moken-portal/web/v1/sample-clothes/find-sample-clothes-collection',
    loading: true,
    server,
    data,
  });
}

/**
 * 供应商筛选接口(用于样衣列表页)
 */
export function getSupplierListByOwner() {
  return http.get<ISupplierFindSupplierByOwnerRes>({
    url: '/moken-portal/web/v1/supplier/find-supplier-by-owner',
    loading: true,
    server,
  });
}

/**
 * 供应商筛选接口(用于保存样衣页)
 */
export function getAllSupplierList() {
  return http.get<ISupplierFindAllSupplierRes>({
    url: '/moken-portal/web/v1/supplier/find-all-supplier',
    loading: true,
    server,
  });
}

/**
 * 获取样衣详情
 */
export function getSampleClothesDetail(data: ISampleClothesFindSampleClothesDetailByIdReq) {
  return http.get<ISampleClothesFindSampleClothesDetailByIdRes>({
    url: '/moken-portal/web/v1/sample-clothes/find-sample-clothes-detail-by-id',
    loading: true,
    server,
    params: data,
  });
}

/**
 * 保存样衣详情
 */
export function saveSampleClothesDetail(data: ISampleClothesSubmitSampleClothesReq) {
  return http.post<ISampleClothesSubmitSampleClothesRes>({
    url: '/moken-portal/web/v1/sample-clothes/submit-sample-clothes',
    loading: true,
    server,
    data,
  });
}

/**
 * 导出版单
 */
export const exportSampleClothes = (params: {
  data: ISampleClothesExportSampleClothesReq;
  filename?: string;
}) => exportByBlob({
  url: '/moken-portal/web/v1/sample-clothes/export-sample-clothes',
  loading: true,
  server,
  data: params.data,
  filename: params.filename,
});

/**
 * 根据订单号更新订单状态（支持批量）
 */
export function updateSampleClothes(data: ISampleClothesUpdateStatusBySampleClothesNumReq) {
  return http.post({
    url: '/moken-portal/web/v1/sample-clothes/update-status-by-sample-clothes-num',
    loading: true,
    server,
    data,
  });
}
