
import { defineComponent, PropType, watch } from 'vue';
import { IDateConfig, IFilterOptionGroup, ISelectConfig } from './type.d';

export default defineComponent({
  name: 'filterGroup',
  props: {
    config: {
      type: Object as PropType<IFilterOptionGroup>,
      default: () => ({}),
    },
  },
  emits: ['change'],
  setup(props, { emit }) {
    const transformValue = (item:ISelectConfig) => {
      return item.options.find(i => item.prop === i.value)?.label || '';
    };
    const transformDateValue = (date: string[]) => {
      if (!date[0] && !date[0]) {
        return '';
      }
      return date;
    };
    watch(props.config, (newVal) => {
      emit('change', newVal);
    }, { deep: true });
    return { transformValue, transformDateValue };
  },
});
