
import { defineComponent, ref } from 'vue';

export default defineComponent({
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '400px',
    },
  },
  emits: ['keydown'],
  setup(props, { emit }) {
    const inputWidth = ref<string>(props.width);
    const keyword = ref<string>(props.value);
    const handleConfirmKeyword = (val:string) => {
      emit('keydown', val);
    };
    const updateKeyWord = ($event:any) => {
      keyword.value = $event.target.value;
    };
    return {
      inputWidth,
      keyword,
      handleConfirmKeyword,
      updateKeyWord,
    };
  },
});
