import { LIST_ITEM } from '../views/list/type.d';

export enum STATUS {
  ALL = '',
  WILL_BEGIN = '0',
  GOING = '1',
  FINISH = '2',
  CACEL = '3'
}

export enum ACTIVE_LABEL {
  GOING = '进行中',
  FINISH = '已完成',
  CACEL = '取消订单',
  EXPORT = '导出'
}

// 样衣状态
export const SAMPLE_STATUS_LIST = [
  { label: '全部', value: STATUS.ALL },
  { label: '待开始', value: STATUS.WILL_BEGIN },
  { label: '进行中', value: STATUS.GOING },
  { label: '已完成', value: STATUS.FINISH },
  { label: '已取消', value: STATUS.CACEL },
];

export const DETAIL_TYPE = {
  CHECK: 1,
  EDIT: 2,
};

export const ACTION_LIST:LIST_ITEM[] = [
  {
    label: ACTIVE_LABEL.GOING,
    icon: 'cj',
    status: STATUS.GOING,
  }, {
    label: ACTIVE_LABEL.FINISH,
    icon: 'icon_tg',
    status: STATUS.FINISH,
  }, {
    label: ACTIVE_LABEL.CACEL,
    icon: 'qx',
    status: STATUS.CACEL,
  }, {
    label: ACTIVE_LABEL.EXPORT,
    icon: 'icon_dc',
    status: undefined,
  },
];
// 天工云版房的supplierId
export const YBF_SUPPLIERID = '1';
