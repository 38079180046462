import { ref } from 'vue';
import { SAMPLE_STATUS_LIST } from '../constant';
import {
  getSampleClothesList,
  getSupplierListByOwner,
  exportSampleClothes,
  getAllSupplierList,
} from '@/modules/task-manage/sample-dress/api/index';
import {
  ISampleClothesFindSampleClothesCollectionListItem, ISampleClothesFindSampleClothesCollectionReq,
  ISupplierFindSupplierByOwnerRes,
  ISampleClothesExportSampleClothesReq,
} from '@/modules/task-manage/sample-dress/api/type.d';

export default (initParams: ISampleClothesFindSampleClothesCollectionReq) => {
  const list = ref<ISampleClothesFindSampleClothesCollectionListItem[]>([]);
  const pagination = ref<{total?: number;pageNum?: number;pageSize: number;}>({
    total: 0,
    pageNum: 1,
    pageSize: 10,
  });
  const tabs = ref<{label: string; total: number;}[]>([]);
  const suppliers = ref<ISupplierFindSupplierByOwnerRes>([]);
  const allSupplierOptions = ref<Array<{value: string; label: string;}>>([]);
  const requestParams = ref<ISampleClothesFindSampleClothesCollectionReq>({});
  // 获取样衣列表
  const getList = async () => {
    try {
      const res = await getSampleClothesList(requestParams.value);
      list.value = res.data.list;
      pagination.value.total = res.data.total;
      return Promise.resolve(res);
    } catch (err) {
      return Promise.reject();
    }
  };
  // 获取样衣供应商下拉项
  const getSupplierOptions = async () => {
    try {
      const res = await getSupplierListByOwner();
      suppliers.value = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject();
    }
  };

  // 获取所有供应商下拉项
  const getAllSupplierOptions = async () => {
    try {
      const res = await getAllSupplierList();
      allSupplierOptions.value = res.data.map(item => ({
        value: item.id || '',
        label: item.supplierName,
      }));
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject();
    }
  };

  // 获取tab相关
  const getTabs = async () => {
    const requestQuee = SAMPLE_STATUS_LIST.map((item) => {
      const newParam = { ...requestParams.value, status: item.value };
      return getSampleClothesList(newParam);
    });
    await Promise.all(requestQuee).then((results) => {
      tabs.value = results.map((res, idx) => {
        return {
          label: SAMPLE_STATUS_LIST[idx].label,
          total: res.data.total,
        };
      });
    });
  };

  // 导出制版单
  const exportOdd = async (params: {
    data: ISampleClothesExportSampleClothesReq;
    filename?: string;
  }) => {
    try {
      await exportSampleClothes({
        data: params.data,
        filename: params.filename,
      });
      Promise.resolve();
    } catch (err) {
      Promise.reject(err);
    }
  };

  // 更新表格
  const reload = async (params: ISampleClothesFindSampleClothesCollectionReq) => {
    requestParams.value = params;
    await getList();
  };

  // 更新tabs和供应商
  const refresh = async (params: ISampleClothesFindSampleClothesCollectionReq) => {
    requestParams.value = params;
    getSupplierOptions();
    getAllSupplierOptions();
    await getTabs();
  };
  const init = async () => {
    await refresh(initParams);
    await reload(initParams);
  };

  init();

  return {
    refresh,
    reload,
    list,
    pagination,
    tabs,
    suppliers,
    allSupplierOptions,
    exportOdd,
  };
};
